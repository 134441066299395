import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { isPresent } from '@progress/kendo-angular-common';
import { DropDownListComponent, PopupSettings } from '@progress/kendo-angular-dropdowns';
import get from 'lodash/get';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { isNullOrUndefined } from 'shared/utils/type.utils';
import { HasSubscriptions } from '../../higher-order/has-subscriptions';

export class CellMenuItem<T = any> {
  text: string;
  subText?: string;
  value: T;
}

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent extends HasSubscriptions
  implements OnInit, AfterViewInit, OnChanges {
  wrappedControlName: object;
  loading = false;
  freeFormItem = {};
  _data = [];

  @ViewChild('list', { static: false }) /*TODO: do we want static to be false?*/
  list: DropDownListComponent;

  @Input()
  id: string;

  @Input()
  set data(data: object[] | string[]) {
    if (isNullOrUndefined(data) && this.freeFormItem && this.allowFreeFormInput) {
      this._data = [this.freeFormItem];
    } else if (this.freeFormItem && this.allowFreeFormInput) {
      this._data = [...data, this.freeFormItem];
    } else {
      this._data = data;
    }
    this.verifySettings();
  }

  @Input()
  label: string;

  @Input()
  value: any;

  @Input()
  dropdownItemTemplate;

  @Input()
  dropdownValueTemplate;

  @Input()
  noDataTemplate;

  @Input()
  filterable: boolean;

  @Input()
  valueField: string;

  @Input()
  textField: string;

  @Input()
  popupSettings: PopupSettings;

  @Input()
  defaultItem: object | string;

  @Input()
  controlName: string;

  @Input()
  group: UntypedFormGroup;

  @Input()
  errorMessage: string;

  _valuePrimitive: boolean;
  @Input()
  set valuePrimitive(isPrimitive) {
    this._valuePrimitive = isPrimitive;

  }

  get valuePrimitive() {
    if (!isPresent(this._valuePrimitive)) {
      return !isPresent(this.valueField);
    }
    return this._valuePrimitive;
  }

  @Input()
  displayType: 'Secondary' | 'Primary' = 'Primary';

  @Input()
  allowFreeFormInput: boolean;

  /**
   * @deprecated Disable your FormControl directly.
   * This input needs to be deprecated
   */
  _disabled = false;
  @Input()
  set disabled(_disabled: boolean) {
    this._disabled = _disabled;
    if (this.group && this.controlName) {
      if (this._disabled) {
        this.group.get(this.controlName).disable();
      } else {
        this.group.get(this.controlName).enable();
      }
    }
  }

  @Input()
  isInATable = false;

  @Input()
  tableOverflow = false;

  @Input()
  warningMessage: string;

  @Input()
  debounceTime = 0;

  @Input()
  minLength = 0;

  @Input()
  digitsOnly: false;

  // If using default values in the data array (never null)
  @Input()
  isParentHandlingLoadingState = false;

  @Input()
  set isLoading(loading: boolean) {
    this.loading = loading;
  }

  // There are only two sizes for form input components.
  // 48px, which occurs outside of tables
  // 40px, which occurs inside of tables
  @Input()
  size: 'Small' | 'Large' = 'Large';

  @Input()
  useVirtualScrolling = false;

  @Input()
  isInActionBar = false;

  @Input()
  emitOnEmptySearch = false;

  @Input()
  readonly = false;

  @Output()
  readonly filterChanged: EventEmitter<any> = new EventEmitter();

  @Output()
  readonly valueChanged: EventEmitter<any> = new EventEmitter();

  @Output()
  readonly opened: EventEmitter<any> = new EventEmitter();

  @Output()
  readonly closed: EventEmitter<any> = new EventEmitter();

  @Output()
  readonly selectionChanged: EventEmitter<any> = new EventEmitter();

  @Output()
  readonly dropDownFocused: EventEmitter<Event> = new EventEmitter<Event>();

  digitsOnlyRegex = /^[0-9]+$/;
  isOpen: boolean;

  virtualScrollOptions = { itemHeight: 28 };

  constructor() {
    super();
  }

  filterChange = evt => {
    this.filterChanged.emit(evt);
  };

  handleChange = evt => {
    this.valueChanged.emit(evt);
  };

  handleSelectionChange = evt => {
    this.selectionChanged.emit(evt);
  };

  isPlaceholder(selection: any): boolean {
    return (
      selection === '' ||
      selection === 'Select' ||
      selection === this.defaultItem ||
      selection === null ||
      (selection && selection.text && selection.text === 'Select')
    );
  }

  ngOnInit() {
    this.wrappedControlName = {
      controlName: this.controlName,
    };
  }

  ngAfterViewInit() {
    if (this.list && this.filterable) {
      this.addSubscription(
        this.list.filterChange
          .asObservable()
          .pipe(
            debounceTime(this.debounceTime),
            filter(value => {
              let matchesFilter = value.length >= this.minLength;
              if (this.allowFreeFormInput) {
                this.setFreeFromValue(value);
              }
              if (this.digitsOnly && !value.match(this.digitsOnlyRegex)) {
                matchesFilter = false;
              }
              if (!this.emitOnEmptySearch && value.trim().length === 0) {
                matchesFilter = false;
              }
              return matchesFilter;
            }),
            tap(value => this.filterChanged.emit(value))
          )
          .subscribe(() => {
            if (this.isParentHandlingLoadingState) return;

            if (this.list.isOpen) {
              this.isLoading = true;
            }
          })
      );
    }
    this.verifySettings();
  }

  setFreeFromValue(v: string) {
    const value = {
      [this.valueField]: v,
      [this.textField]: v,
      customValue: v,
    };
    this.isLoading = false;
    this.freeFormItem = value;
  }

  public handleOpen(): void {
    this.isOpen = true;
    this.opened.emit(this.controlName);
  }

  public handleClose(): void {
    this.isOpen = false;
    this.closed.emit(this.controlName);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isParentHandlingLoadingState) return;
    const data: object[] | string[] | any = get(changes, '_data.currentValue');
    if (this.list && this.filterable && this._data) {
      this.isLoading = false;
    }
  }

  handleKeypress(event: KeyboardEvent) {
    this.isOpen = true;
    if (event.code === 'Enter') {
      this.list.toggle(true);
    }
  }

  isDisabled(): boolean {
    if (typeof this.disabled === 'boolean') {
      return this.disabled;
    } else if (this.group && this.group.get && this.group.get(this.controlName)) {
      return this.group.get(this.controlName).disabled;
    } else {
      return false;
    }
  }

  public hasError(): boolean {
    if (!this.group) {
      return false;
    }
    const control = this.group.get(this.controlName);
    if (!control) {
      return false;
    }
    return !control.valid && control.touched;
  }

  // This function catches implementations with deprecated usage of valuePrimitive flag and automatically fixes the setting
  // Exception conditions are referenced from kendo source code - progress-kendo-angular-dropdowns.mjs
  verifySettings() {
    try {
      const data = this._data && this._data.length > 0 ? this._data[0] : undefined;
      const dataValue = (data && typeof data === "object" &&  isPresent(this.valueField)) ? get(data, this.valueField, undefined) : data;
      if (isPresent(this.group) && isPresent(this.controlName)) {
        const control = this.group.get(this.controlName);

        if (this.valuePrimitive && !isNullOrUndefined(dataValue) && typeof dataValue === "object" ) {
          console.warn('Fix dropdown valuePrimitive setting on ', this.controlName, '. valuePrimitive should be false.');
          this.valuePrimitive = false
        }

        if (this.valuePrimitive && control && !isNullOrUndefined(control.value) && typeof control.value === "object" ) {
          console.warn('Fix dropdown valuePrimitive setting on ', this.controlName, '. valuePrimitive should be false.');
          this.valuePrimitive = false
        }

        if (this.valuePrimitive === false && control && !isNullOrUndefined(control.value) && this.valueField !== 'value' && typeof control.value !== 'object'){
          console.warn('Fix dropdown form control initialization for ', this.controlName ,' value: ', control.value, '. Expected default value to be object, got primitive.')
          this.valuePrimitive = true;
        }
      }
    } catch(e){
      console.error('Error in dropdown valuePrimitive setting: ', e);
    }
  }
}
