import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { saveAs } from '@progress/kendo-file-saver';
import cloneDeep from 'lodash/cloneDeep';
import has from 'lodash/has';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { ToastService } from 'shared/services/toast.service';
import { dateUtils } from 'shared/utils/date.utils';

import {
  AllocationMethodService,
  AllocationService,
  LookupCollections,
  LookupService,
  PdaClassification,
  PdaService,
  PredeterminedAllocationStatusCollection,
} from '@gms/allocation-api';
import {
  CreatePda,
  CreatePdaError,
  CreatePdaSuccess,
  EAllocationsActions,
  ExportAllocationDetails,
  ExportAllocationDetailsFailure,
  ExportAllocationDetailsSuccess,
  FetchAllocationDetails,
  FetchAllocationDetailsError,
  FetchAllocationDetailsSuccess,
  FetchAllocationLookup,
  FetchAllocationLookupFailure,
  FetchAllocationLookupSuccess,
  FetchAllocationMethod,
  FetchAllocationMethodError,
  FetchAllocationMethodSuccess,
  FetchAllocations,
  FetchAllocationsError,
  FetchAllocationsSuccess,
  FetchIncompletePdas,
  FetchIncompletePdasFailure,
  FetchIncompletePdasSuccess,
  FetchMissingPdas,
  FetchMissingPdasFailure,
  FetchMissingPdasSuccess,
  FetchPdaById,
  FetchPdaByIdFailure,
  FetchPdaByIdSuccess,
  FetchPdaClassifications,
  FetchPdaClassificationsFailure,
  FetchPdaClassificationsSuccess,
  FetchPdaDetails,
  FetchPdaDetailsFailure,
  FetchPdaDetailsPayload,
  FetchPdaDetailsSuccess,
  FetchPdas,
  FetchPdasFailure,
  FetchPdasSuccess,
} from './allocations.actions';

@Injectable()
export class AllocationsEffects {
  constructor(
    private _actions$: Actions,
    private _allocationsService: AllocationService,
    private _lookupService: LookupService,
    private _allocationMethodService: AllocationMethodService,
    private _pdaService: PdaService,
    private _router: Router,
    private _toastService: ToastService
  ) {}

  FetchAllocations$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchAllocations>(EAllocationsActions.FETCH_ALLOCATIONS),
      map((action: FetchAllocations) => action.payload),
      switchMap(payload => {
        let sortQuery = ``;
        if (payload.sortDescriptors) {
          payload.sortDescriptors.forEach(sortDescriptor => {
            sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
          });
        }
        return this._allocationsService
          .getAllocations(
            dateUtils.getDateAsYYYY_MM_DD(new Date(payload.dateBegin)),
            payload.operatorId,
            payload.dateEnd ? dateUtils.getDateAsYYYY_MM_DD(new Date(payload.dateEnd)) : null,
            payload.tspId ? payload.tspId : null,
            payload.confirmingEntityId,
            payload.accountingPeriod ? payload.accountingPeriod.split('T')[0] : null,
            payload.pageSize,
            payload.pageNumber,
            sortQuery,
            payload.locationId
          )
          .pipe(
            map(allocationsCollection => {
              return new FetchAllocationsSuccess({
                allocations: allocationsCollection.allocations,
                totalAllocationsCount: allocationsCollection.total,
              });
            }),
            catchError(error => of(new FetchAllocationsError({ error: error })))
          );
      })
    )
  );

  FetchAllocationDetails$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchAllocationDetails>(EAllocationsActions.FETCH_ALLOCATION_DETAILS),
      map((action: FetchAllocationDetails) => action.payload),
      switchMap(payload => {
        let sortQuery = ``;
        if (payload.sortDescriptors) {
          payload.sortDescriptors.forEach(sortDescriptor => {
            sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
          });
        }
        return this._allocationsService
          .getAllocationDetails(
            payload.locationId,
            payload.confirmingEntityId,
            dateUtils.getDateAsYYYY_MM_DD(new Date(payload.dateBegin)),
            payload.viewBy,
            dateUtils.getDateAsYYYY_MM_DD(new Date(payload.dateEnd)),
            payload.accountingPeriod ? payload.accountingPeriod.split('T')[0] : null,
            payload.pageSize,
            payload.pageNumber,
            sortQuery
          )
          .pipe(
            map(allocationsCollection => {
              return new FetchAllocationDetailsSuccess({
                allocationDetails: allocationsCollection.allocationDetails,
                totalAllocationsCount: allocationsCollection.total,
              });
            }),
            catchError(error => of(new FetchAllocationDetailsError({ error: error })))
          );
      })
    )
  );

  FetchAllocationMethod$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchAllocationMethod>(EAllocationsActions.FETCH_ALLOCATIONS_METHOD),
      switchMap(() => {
        return this._allocationMethodService.getAllocationMethod().pipe(
          map(allocationMethodCollection => {
            return new FetchAllocationMethodSuccess(allocationMethodCollection.allocationMethods);
          }),
          catchError(error => of(new FetchAllocationMethodError({ error: error })))
        );
      })
    )
  );

  ExportAllocationDetails$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<ExportAllocationDetails>(EAllocationsActions.EXPORT_ALLOCATION_DETAILS),
      map((action: ExportAllocationDetails) => action.payload),
      switchMap(payload => {
        let sortQuery = ``;
        const saveData = (data: string) => {
          const trimData = data.replace(/"/g, '');
          const blob = new Blob([atob(trimData)], { type: 'text/csv' });
          const fileName = `Allocation Details - ${dateUtils.getDateStringAsMM_YYYY(
            payload.accountingPeriod
          )} ${payload.viewBy}.csv`;

          saveAs(blob, fileName);
        };
        if (payload.sortDescriptors) {
          payload.sortDescriptors.forEach(sortDescriptor => {
            sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
          });
        }
        return this._allocationsService
          .exportAllocationDetails(
            /// will change
            payload.locationId,
            payload.confirmingEntityId,
            dateUtils.getDateAsYYYY_MM_DD(new Date(payload.dateBegin)),
            payload.viewBy,
            dateUtils.getDateAsYYYY_MM_DD(new Date(payload.dateEnd)),
            payload.accountingPeriod ? payload.accountingPeriod.split('T')[0] : null,
            payload.pageSize,
            payload.pageNumber,
            sortQuery
          )
          .pipe(
            map(response => {
              saveData(response as any); // will change
              return new ExportAllocationDetailsSuccess();
            }),
            catchError(error => of(new ExportAllocationDetailsFailure(error)))
          );
      })
    )
  );

  CreatePda$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<CreatePda>(EAllocationsActions.CREATE_PDA),
      map((action: CreatePda) => action.payload),
      switchMap(payload => {
        return this._pdaService.addPda(payload).pipe(
          map(() => {
            this._router.navigate(['/flowing-gas/pda']);
            this._toastService.success('Success: The PDA has been successfully submitted');
            return new CreatePdaSuccess();
          }),
          catchError(error => {
            return of(new CreatePdaError({ error: error }));
          })
        );
      })
    )
  );

  FetchPdas$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchPdas>(EAllocationsActions.FETCH_PREDETERMINED_ALLOCATIONS),
      map((action: FetchPdas) => action.payload),
      switchMap(payload => {
        let sortQuery = ``;
        if (payload.sortDescriptors) {
          const sortDescriptors = cloneDeep(payload.sortDescriptors);
          if (!has(payload.sortDescriptors, 'locationName')) {
            sortDescriptors.push({
              field: 'locationName',
              dir: 'asc',
            });
          }

          sortDescriptors.forEach(sortDescriptor => {
            sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
          });
        }
        return this._pdaService
          .getPda(
            dateUtils.getDateAsYYYY_MM_DD(payload.dateBegin),
            dateUtils.getDateAsYYYY_MM_DD(payload.dateEnd),
            payload.tspId,
            payload.locationId,
            payload.pagination.pageSize,
            payload.pagination.pageNumber,
            sortQuery
          )
          .pipe(
            map(pdas => {
              return new FetchPdasSuccess({
                collection: pdas.predeterminedAllocations,
                totalPredeterminedAllocations: pdas.total,
              });
            }),
            catchError(error => of(new FetchPdasFailure({ error: error })))
          );
      })
    )
  );

  FetchPdaById$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchPdaById>(EAllocationsActions.FETCH_PREDETERMINED_ALLOCATION_BY_ID),
      map((action: FetchPdaById) => action.payload),
      switchMap(payload => {
        return this._pdaService
          .getPdaById(
            payload.pdaId,
            dateUtils.getDateAsYYYY_MM_DD(payload.dateBegin),
            dateUtils.getDateAsYYYY_MM_DD(payload.dateEnd)
          )
          .pipe(
            map(pda => {
              return new FetchPdaByIdSuccess({
                predeterminedAllocation: pda,
              });
            }),
            catchError(error => of(new FetchPdaByIdFailure({ error: error })))
          );
      })
    )
  );

  FetchIncompletePdas$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchIncompletePdas>(EAllocationsActions.FETCH_INCOMPLETE_PDAS),
      map((action: FetchIncompletePdas) => action.payload),
      switchMap(payload => {
        const { tspId } = payload;

        return this._pdaService.getIncompletePdas(3, tspId).pipe(
          map((pdas: PredeterminedAllocationStatusCollection) => {
            return new FetchIncompletePdasSuccess({
              predeterminedAllocationStatusCollection: pdas,
            });
          }),
          catchError(error => of(new FetchIncompletePdasFailure({ error: error })))
        );
      })
    )
  );

  FetchMissingPdas$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchMissingPdas>(EAllocationsActions.FETCH_MISSING_PDAS),
      map((action: FetchMissingPdas) => action.payload),
      switchMap(payload => {
        const { gasDays, tspId } = payload;

        return this._pdaService.getMissingPdas(gasDays, tspId).pipe(
          map((pdas: PredeterminedAllocationStatusCollection) => {
            return new FetchMissingPdasSuccess({
              predeterminedAllocationStatusCollection: pdas,
            });
          }),
          catchError(error => of(new FetchMissingPdasFailure({ error: error })))
        );
      })
    )
  );

  // Un)comment when pdaService includes the getExpiredPda endpoint.
  //
  // FetchExpiredPdas$: Observable<any> = createEffect(() => this._actions$.pipe(
  //   ofType<FetchExpiredPdas>(EAllocationsActions.FETCH_MISSING_PDAS),
  //   map((action: FetchExpiredPdas) => action.payload),
  //   switchMap(payload => {
  //     return this._pdaService.getExpiringPdas(payload.gasDays).pipe(
  //       map((pdas: PredeterminedAllocationStatusCollection) => {
  //         return new FetchExpiredPdasSuccess({
  //           predeterminedAllocationStatusCollection: pdas,
  //         });
  //       }),
  //       catchError(error => of(new FetchExpiredPdasFailure({ error: error })))
  //     );
  //   })
  // ));

  FetchPdaDetails$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchPdaDetails>(EAllocationsActions.FETCH_PREDETERMINED_ALLOCATION_DETAILS),
      map((action: FetchPdaDetails) => action.payload),
      switchMap((payload: FetchPdaDetailsPayload) => {
        return this._pdaService
          .getPdaDetails(
            payload.locationId,
            payload.locationNumber,
            dateUtils.getDateAsYYYY_MM_DD(payload.dateBegin),
            dateUtils.getDateAsYYYY_MM_DD(payload.dateEnd),
            payload.pdaId,
            payload.accountIds,
            payload.allocationMethodCode
          )
          .pipe(
            map(pdaDetails => {
              pdaDetails.predeterminedAllocationDetails = pdaDetails.predeterminedAllocationDetails.sort(
                (detail1, detail2) => {
                  if (detail1.flowDirection === detail2.flowDirection) {
                    return detail1.serviceRequestEntityName > detail2.serviceRequestEntityName
                      ? 1
                      : -1;
                  }
                  return detail1.flowDirection === payload.locationNormalFlowDirection ? -1 : 1;
                }
              );
              return new FetchPdaDetailsSuccess(pdaDetails);
            }),
            catchError(error => of(new FetchPdaDetailsFailure({ error: error })))
          );
      })
    )
  );

  FetchAllocationLookup$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchAllocationLookup>(EAllocationsActions.FETCH_ALLOCATION_LOOKUP),
      switchMap(() => {
        return this._lookupService.getLookup().pipe(
          map(
            (lookupCollection: LookupCollections) =>
              new FetchAllocationLookupSuccess(lookupCollection)
          ),
          catchError(error => of(new FetchAllocationLookupFailure({ error })))
        );
      })
    )
  );

  FetchPdaClassifications$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchPdaClassifications>(EAllocationsActions.FETCH_PDA_CLASSIFICATIONS),
      switchMap(() => {
        return this._allocationsService.getPdaClassifications().pipe(
          map(
            (classifications: PdaClassification[]) => new FetchPdaClassificationsSuccess(classifications)
          ),
          catchError(error => of(new FetchPdaClassificationsFailure({ error })))
        )
      })
    )
  );
}
