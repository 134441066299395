<div *ngIf="group" class="dropdown-container"
[formGroup]="group"
[class.is-in-action-bar]="isInActionBar"
[class.secondary-display-type]="displayType === 'Secondary'"
>
  <div class="label-wrapper">
    <label *ngIf="label" class="dropdown-label">{{ label }}</label>
  </div>
  <gms-inline-error-message [control]="group.controls[controlName]" [isInATable]="isInATable" [tableOverflow]="tableOverflow">
  <kendo-dropdownlist
    #list
    [id]="(!!label ? label+'-cy' : id+'-cy')"
    [data]="_data"
    [value]="value"
    [valueField]="valueField"
    [textField]="textField"
    [popupSettings]="popupSettings"
    [defaultItem]="defaultItem"
    [filterable]="filterable"
    [loading]="loading"
    (valueChange)="handleChange($event)"
    (selectionChange)="handleSelectionChange($event)"
    [formControlName]="controlName"
    [valuePrimitive]="valuePrimitive"
    [class.drop-down-error]="hasError()"
    [class.is-in-a-table]="isInATable"
    [class.dropdown-is-open]="isOpen && !hasError()"
    style="width: 100%"
    [class.dark-blue-gray]="isPlaceholder(group?.get(controlName)?.value)"
    (open)="handleOpen()"
    (close)="handleClose()"
    (keypress)="handleKeypress($event)"
    (focus)="dropDownFocused.emit()"
    [virtual]="useVirtualScrolling ? virtualScrollOptions : null"
    [readonly]="readonly"
  >
    <ng-template *ngIf="dropdownItemTemplate" kendoDropDownListItemTemplate let-dataItem>
      <ng-container
        *ngTemplateOutlet="dropdownItemTemplate; context: { dataItem: dataItem }"
      ></ng-container>
    </ng-template>
    <ng-template *ngIf="dropdownValueTemplate" kendoDropDownListValueTemplate let-dataItem>
      <ng-container
        *ngTemplateOutlet="dropdownValueTemplate; context: { dataItem: dataItem }"
      ></ng-container>
    </ng-template>
    <ng-template *ngIf="noDataTemplate" kendoDropDownListNoDataTemplate>
      <ng-container *ngTemplateOutlet="noDataTemplate"></ng-container>
    </ng-template>
  </kendo-dropdownlist>
  </gms-inline-error-message>
  <div
    *ngIf="
      !group?.get(controlName)?.valid &&
      group?.get(controlName)?.touched &&
      !group?.get(controlName)?.errors?.required
    "
    class="global-error-text"
  >
    {{ errorMessage }}
  </div>
  <div
    *ngIf="
      !group?.get(controlName)?.valid &&
      group?.get(controlName)?.touched &&
      group?.get(controlName)?.errors?.required
    "
    class="global-error-text required-field-error-text"
  >
    {{ 'required field' | uppercase }}
  </div>
  <app-message
    *ngIf="warningMessage"
    type="warning"
    [messages]="[{ type: 'warning', message: warningMessage }]"
  ></app-message>
</div>
<!-- Usage without a group definition should be Deprecated -->
<div *ngIf="!group" class="dropdown-container" [class.is-in-action-bar]="isInActionBar">
  <label *ngIf="label" class="dropdown-label">{{ label }}</label>
  <kendo-dropdownlist
    [id]="(!!label ? label+'-cy' : id+'-cy')"
    [data]="_data"
    [value]="value"
    [popupSettings]="popupSettings"
    [defaultItem]="defaultItem"
    [filterable]="filterable"
    (valueChange)="handleChange($event)"
    (selectionChange)="handleSelectionChange($event)"
    (filterChange)="filterChange($event)"
    [valuePrimitive]="valuePrimitive"
    [class.is-in-a-table]="isInATable"
    style="width: 100%"
    (open)="opened.emit(controlName)"
    (focus)="dropDownFocused.emit()"
  >
    <ng-template *ngIf="dropdownItemTemplate" kendoDropDownListItemTemplate let-dataItem>
      <ng-container
        *ngTemplateOutlet="dropdownItemTemplate; context: { dataItem: dataItem }"
      ></ng-container>
    </ng-template>
    <ng-template *ngIf="dropdownValueTemplate" kendoDropDownListValueTemplate let-dataItem>
      <ng-container
        *ngTemplateOutlet="dropdownValueTemplate; context: { dataItem: dataItem }"
      ></ng-container>
    </ng-template>
    <ng-template *ngIf="noDataTemplate" kendoDropDownListNoDataTemplate>
      <ng-container *ngTemplateOutlet="noDataTemplate"></ng-container>
    </ng-template>
  </kendo-dropdownlist>
  <div *ngIf="errorMessage" class="global-error-text">
    {{ errorMessage }}
  </div>
</div>
